#roadmap-container {
    width: 100%;
    height: 100%;
    border: 0px;
    padding: 0px;
    margin: 0px;
    background-size: cover;
    background-position: center;
    height: max(50vw,400px);
    position: relative;
    background-color: burlywood;
}

.closeBTN {
    position: fixed;
    right : 3vw;
    top : 3vh;
    z-index: 1000000;
}
#zoom_div {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 1000001;
}
#zi_icon , #zo_icon{
    width: 14vw;
    max-width: 90px;
    min-width: 30px;
    height: auto;
}
#start_div {
    color: white;
    display: flex;
    flex-wrap: wrap;
    top:0px;
    left:0px;
    background-color: rgba(0,0,0,1);
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center; 
    transition: all 1s ease;
    filter: opacity(100%);  
    z-index: 1000002;
}
#start_div_2 {
    max-width: 300px;   
    flex-wrap: wrap;
}
#display_div {
    display: none;
    position: fixed;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
#founder_div , #partner_div {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
#vdo_div {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
#paper_div {
    display: none;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    width: 100%;
    height: 100%;
    
}
#pgbar_label{
    font-size: 20px;
}
#founder_img , #partner_img {
    justify-content: center;
    align-items: center;
    width: 0%;
    height: auto;
    border-radius: 30px;
    filter: drop-shadow(10px 10px 30px rgba(0,0,0,1));
    transition:all .7s ease ;
}
#partner_img {
    transition:all 2.5s ease ;
    filter: none;
}

#button_to_begin {
    display: none;
    font-family: 'Cinzel Decorative', cursive;
    background-color: white;
    color: black;
    padding: 20px;
    font-size: 25px;
    font-weight: 400;
    border-radius: 30px;
}

#gaurdian_text {
    width: 100%;
    font-family: 'Cinzel Decorative', cursive;
    font-size: .8vw;
    color:white;
    position: absolute;
    bottom: -.5vw;
}
.roadmap-div{
    background-size: cover;
    background-position: center;
    height: max(50vw,400px);
}

#nft1{
    content: url("sample1_4.jpg");
}

#sample-layer2-part1 {
    width: 100%;
    height: 100%;
    padding: 0px;
}
#sample-layer2-part2 {
    width: 50%;
    height: 50%;
    border: 0px;
    padding: 0px;
    margin: 0px;
}
#sample-layer2-part2-sub1 {
    /*position:static;*/
    height: 100vh;
    width: 100%;
    border: 0px;
    padding: 0px;
    margin: 0px;
    background-color: #161722;
    
}

#sample-layer2-blur{
    display: block;
    width: 100%;
}

#sample-layer2-table{
    padding: 0px;
    margin: 0px;
    border: 0px;
    width: 100%;
    max-width:100%; 
}

.sample-layer2-td{
    background-color: #231c16;
    height: 400px;
    border: 0px;
    padding: 0px;
    margin: 0p;
}

@media (max-width: 1000px){

}