body, html {
  height: 100vh;
  margin: 0;
  width: 100vw;
  background-color: rgb(215,111,5);
  /*scroll-behavior: smooth;*/
  background-position:center;
  background-size:auto 100%;
  background-attachment: fixed;
  font-family: 'Prompt', sans-serif;
  font-size: 1vw;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}


@font-face {
  font-family: 'digit7mono';
  src: local('digit-7'), url('digital-7(mono).ttf') format('truetype');
}
@font-face {
  font-family: 'digit7';
  src: local('digit-7'), url('digital-7.ttf') format('truetype');
}