/*Desktop View*/
.gap{
    width: 100%;
    display: flex;
    justify-content: right;
}
#scrollBG_div {
  /*display: none;*/
  position: fixed;
  top:0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1000004;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0,.5);
  filter: opacity(0);
  transition : all 1s ease;
}
#scrollBG_container {
  font-family: 'Cinzel Decorative', cursive;
  font-size: min(10vh,30px);
  height:70%;
  width: 100vh;
  transition : all 1s ease;
  background-image: url('../scroll1.gif');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
}
#font_wallet {
  font-size: 1.2rem;
}

.gc1{
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0px;
  margin: 0px 0px 0px 0px;
  border-radius:30px;
  justify-content: center;
}

.stu_img{
  width: 100%;
  border-radius:30px;
}

.gcc1{
  width: 100vw;
  font-size: 50px;
  text-align: center;
  padding: 10px;
  width: 33.333%;
  word-wrap: break-word;
  box-sizing: border-box;
  border-radius:30px;
  justify-content: center;
  min-width: 200px;
}

.upmost_svg {
  display: inline-block;
  float: right;
  height: min(4.5vw,65px);
  margin-right: min(.5vw,20px);
  padding: 10px;
  align-items: center;
}
.upmost_svg:hover {
  padding: 0px;
}

.nn{
    font-family: 'Quicksand', sans-serif;
    font-size: 30px;
    width: 100%;
}

.header_menu{
  top: 0px;
  width: 100%;
  position: fixed;
  z-index: 10000;
}

.header {
    /*max-width: 820px;*/
    width: 100%;
    display: flex;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    height: 55px;
}

.logo_icon{
  position: fixed;
  top:0px;
  max-height: 45px;
  width: auto;
  padding: 2px;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  filter: drop-shadow(0 0 40px black);
  background-color: rgba(255, 255, 255,.7);
  border-radius: 10px;
}

.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
    display: flex;
    align-items: right;
    justify-content: right;
}

.nav-options {
    width: 100%;
    padding-left: 25px;
    display:grid;
    grid-template-columns: 90px 80px 150px;
    list-style-type: none;
    display: none;
}
  
.mobile-option {
    display: none;
}
  
.option :hover {
    color: white;
}

.signin-up {
    width: auto;
    display: flex;
    text-align: right;
    padding: 0px 5px;
    list-style-type: none;
}

.sign-in {
    width: auto;
    padding-right: 50px;
    right: 0px;
    align-self: right;
}

.sign-in :hover {
    color: white;
}
.sign-up{
  position: relative;
  right: 0px;
  width: 100%;
  
}
.signup-btn {
    padding: 10px 10px;
    height: 2.4rem;
    border-radius: 3px;
    background: rgb(222, 9, 241);
    color: white;
    cursor: pointer;
    align-items: center;
}
  
.connect_button1 {
  font-family: 'Cinzel Decorative', cursive;
  font-weight: 300;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 14px;
  color: rgb(255, 255, 255);

  cursor: pointer;
  align-items: center;
  background-color:rgba(0, 0, 0, 0.5);
  border-radius:28px;
  margin-top:10px;
  margin-right: 20px;
  margin-bottom: auto;
  padding:10px;
  padding-left: 20px;
  padding-right: 20px;
  display: none;
}

.connect_button1:hover {
    background-color:#bbff00;
    color: #000;
}

.connect_button1:active {
    position:relative;
    top:1px;
}
  
.mobile-menu {
  display: none;
}

.menu-icon {
  width: 45px;
  height: 45px;
  z-index: 10000;
  position: relative;
}

@media (max-width: 1000px) {
  /*Mobile View */
  .logo_icon{
    height: 30px;
    width: auto;
    background-color: rgba(255, 255, 255,1);
  }

  .header {
    /*max-width: 820px;*/
    width: 100%;
    display: flex;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    height: 55px;
  }

  .nav-options {
    z-index: 10;
    top: 50px;
    background:transparent;
    display: flex;
    position: absolute;
    left: -104vw;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }

  .nav-options.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
  }

  .list_menu{
    height: 200%;
    width: 100%;
    padding-left: 0px;
    border-radius: 40px ;
    background-color: white;
    padding: 10px;
  }
  .mobile_b1{
    font-family: 'Cinzel Decorative', cursive;
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: 500;
    display: none;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
  }
/*
  .sign-up {
    background: rgb(0, 0, 0);
    border-radius: 3px;
    color: white;
    padding: 20px 0px;
    width: 80%;
    align-self: center;
    border-radius: 3px;
  }*/

  #bt1 {
    display: none;
  }

  .mobile-menu {
    display: block;
  } 

  .upmost_svg {
    display: block;
    float:right;
    margin:0px;
    margin-left: 10px;
    height: 50px;
    padding: 10px;
    align-items: center;
  }
  .upmost_svg:hover {
    padding: 0px;
  }
  #scrollBG_container {
    font-family: 'Cinzel Decorative', cursive;
    font-size: 20px;
    width: 85vw;
    height: 55vw;
  }
}